import React, { useContext, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { parse } from "qs";
import { app } from "config";

import { DiContext, useAsync } from "app/common";
import { useOnError } from "hooks/useOnError";
import { CreateUserDTO, userService } from "app/infra/user";

import { Button, Checkbox, Form, Input, notification, Tooltip } from "antd";
import { InfoCircle, Lock, User, ChevronLeft, ChevronRight } from "react-iconly";
import logo from "assets/images/awg/logo/logo-dark.svg";
import popupBg from "assets/images/awg/auth/popup-bg-big.svg";

export const RegisterPage = () => {
  const history = useHistory();
  const location = useLocation();

  const { dispatch, apiService } = useContext(DiContext);
  const userSrv = userService({ dispatch, apiService });

  const { execute, pending, value, error } = useAsync((data: CreateUserDTO) => {
    return userSrv.create(data);
  }, false);

  useEffect(() => {
    if (value !== null) {
      notification.info({ message: "Redirecting to login" });

      setTimeout(() => {
        history.push("/auth/login");
      }, 3000);
    }
  }, [value]);

  useOnError(error);

  const queryParams = parse(location.search, { ignoreQueryPrefix: true });

  return (
    <div className="auth-wrapper">
      <div className="auth-wrapper-form-container">
        {/* <img className="auth-popup-bg" src={popupBg} alt={popupBg} /> */}
        <Form
          layout="vertical"
          onFinish={execute}
        >
          <div className="logo">
            <img src={logo} alt={`${app.name} Logo`} height={40} />
          </div>

          <Form.Item
            name="email"
            initialValue={queryParams.email}
            label={(
              <Tooltip title="The email address you used to purchase your ticket">
                <span style={{ display: "flex", alignItems: "center" }}>
                  Registration email{" "}
                  <InfoCircle set="light" size="small" style={{ marginLeft: 6 }} />
                </span>
              </Tooltip>
            )}
            rules={[
              {
                type: "email",
                message: "Not a valid email address",
              },
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="info@example.com"
              prefix={<User set="light" />}
              disabled={!!queryParams.email}
            />
          </Form.Item>

          <Form.Item
            name="reference"
            initialValue={queryParams.reference}
            label={(
              <Tooltip title="Please enter your 5 digit ticket reference code from your ticket confirmation email e.g: 3ABC-1">
                <span style={{ display: "flex", alignItems: "center" }}>
                  Ticket Code{" "}
                  <InfoCircle set="light" size="small" style={{ marginLeft: 6 }} />
                </span>
              </Tooltip>
            )}
            rules={[{ required: true, message: "This field is required" }]}
          >
            <Input
              placeholder="e.g ABBC-6"
              prefix={<User set="light" />}
              disabled={!!queryParams.reference}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              className="auth-btn"
              htmlType="submit"
              loading={pending}
              block={true}
              style={{ fontWeight: "bold", textTransform: "uppercase", letterSpacing: "1px" }}
            >
              Login
            </Button>
          </Form.Item>

          <hr />

          <Form.Item style={{ textAlign: "center" }}>
            <Link to="/auth/login" className="didnt-receive-login">
              Login via magic link
            </Link>
          </Form.Item>

          <Form.Item>
            <div style={{ textAlign: "center", borderTop: "2px solid", marginBottom: "5px", borderColor: "#0000001A" }}>
              <a
                href={app.landingURL}
                target="_blank"
                rel="noreferrer"
                style={{
                  display: "block",
                  marginTop: "20px",
                  fontWeight: "bold",
                  opacity: 0.7,
                }}
              >
                Don't have a ticket? Get one now!
              </a>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
