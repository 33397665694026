import React, { useContext, useEffect, useState } from "react";
import { app } from "config";

import { DiContext, useAsync } from "app/common";
import { useOnError } from "hooks/useOnError";
import { authService } from "app/infra/auth";

import { Button, Form, Input, notification } from "antd";
import { User } from "react-iconly";
import logo from "assets/images/awg/logo/logo-dark.svg";
import popupBg from "assets/images/awg/auth/popup-bg-small.svg";

interface ResetPasswordFormProps {
  email: string;
}

export const ResetPasswordPage = () => {
  const { apiService, dispatch } = useContext(DiContext);
  const authSrv = authService({ apiService, dispatch });
  const [successMessage, setSuccessMessage] = useState(false);

  const { execute, value, pending, error } = useAsync<{ ok?: boolean }>(({ email }: ResetPasswordFormProps) => {
    return authSrv.forgotPasswordForEmail(email);
  }, false);

  useOnError(error);

  useEffect(() => {
    if (value && value.ok) {
      notification.success({
        message: "Please check your email to reset your password. Make sure to check your spam folder.",
      });

      setSuccessMessage(true);
    }
  }, [value]);

  return (
    <div className="auth-wrapper">
      <div className="auth-wrapper-form-container">
        {/* <img className="auth-popup-bg" src={popupBg} alt={popupBg} /> */}
        <Form
          layout="vertical"
          onFinish={execute}
        >
          <div className="logo">
            <img src={logo} alt={`${app.name} Logo`} height="40" />
          </div>

          {successMessage && (
            <div className="ant-row ant-form-item">
              <strong>Your reset password email is on the way!</strong>
              It can take up to 5-10 minutes to arrive, please check your spam folder,
              before contacting support for additional assistance.
            </div>
          )}

          <Form.Item
            name="email"
            rules={[
              {
                type: "email",
                message: "Not a valid email address",
              },
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Input
              type="email"
              placeholder="Email"
              prefix={<User set="light" />}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              style={{ whiteSpace: "normal", height: "auto", marginBottom: "10px" }}
              className="auth-btn"
              htmlType="submit"
              loading={pending}
              block
            >
              Send reset password link
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
