import React, { lazy, Suspense, useEffect, useMemo, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { pusher } from "config";

import { useOnMount } from "hooks/useOnMount";

import { DiContext } from "app/common";
import { Layout } from "components/Layout/Layout";

import {
  SchedulePage,
  StagePage,
  TrackPage,
  PrivacyPage,
  TermsPage,
} from "app/presentation/dashboard";

import { ApiService, AppDispatch, PusherBeam, RootState } from "services";
import { connect, DispatchProp, Matching } from "react-redux";

import { useDebounce } from "hooks/useDebounce";

import { AppModes, mode } from "app/infra/app";
import { logout } from "app/infra/auth";
import { ConnectionService, UserEntity } from "app/infra/user";
import { TicketTypeService } from "app/infra/ticketType";
import { ChatService } from "app/infra/chat";
import { NotificationBareData, PushNotificationStore } from "app/infra/pushNotification/pushNotification.store";

import { ConfirmResetPasswordPage, LoginPage, PasswordlessLogin, PasswordlessSession, RegisterPage, ResetPasswordPage } from "app/presentation/authentication";
import { BoothView } from "app/presentation/dashboard/booths/booth.view";
import { ReplayPage } from "app/presentation/dashboard/replay/replay.page";
import { ViewReplayPage } from "app/presentation/dashboard/replay/viewReplay.page";

import { CompanyUpdate } from "components/Company/CompanyUpdate";
import { UserUpdate } from "components/User/UserUpdate";
import { User } from "components/User/User";

import PerkList from "components/Perk/PerkList";

import { isMobile } from "react-device-detect";

import { notification } from "antd";

import { CurrentTalkUpdater } from "app/presentation/common/currentTalkUpdater";

import ScrollToTop from "app/presentation/scrollToTop";
import { ChatPixel } from "components/Chat/ChatPixel";
import { Chat } from "components/Chat/Chat";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";
import { Live } from "app/presentation/dashboard/schedule/live";

interface RouterProps extends Matching<{ token: null } & DispatchProp, unknown> {
  token: string | null;
  isLoggedIn: boolean;
  isAdmin: boolean;
  me: UserEntity | null;
  dispatch: AppDispatch;
}

const mapStateToProps = (state: RootState) => {
  return {
    token: state.authStore.token,
    isLoggedIn: state.authStore.isLoggedIn,
    isAdmin: state.authStore.isAdmin,
    me: state.userStore.byId["me"],
  };
};

const AdminPage = lazy(() => import("app/presentation/admin/admin.page"));

export const Router = connect(mapStateToProps)((props: RouterProps) => {
  const [expired, setExpired] = useState(false);

  const { dispatch } = props;

  const isProfileNotComplete = useMemo(() => {
    return props.me ? !props.me.isComplete : false;
  }, [props.me]);

  const debouncedExpired = useDebounce(expired, 150);
  useEffect(() => {
    if (debouncedExpired) {
      notification.error({ message: "You were logged out, because this login was used on another device or browser." });

      setTimeout(() => { setExpired(true); }, 100);
    }
  }, [debouncedExpired]);

  const [apiService] = useMemo(() => {
    return [
      new ApiService(
        props.token || "",
        () => { logout({ dispatch }); },
        () => { setExpired(true); },
      ),
    ];
  }, [props.token]);

  const entityServices = useMemo(() => {
    const commonDeps = { dispatch, apiService };

    return {
      ConnectService: ConnectionService(commonDeps),
      TicketTypeService: TicketTypeService(commonDeps),
      ChatService: ChatService(commonDeps),
    };
  }, [apiService]);

  const pusherBeamService = useMemo(() => {
    return new PusherBeam(pusher.beam.instanceId, pusher.beam.tokenProviderURL);
  }, []);

  useEffect(() => {
    if (props.isLoggedIn) {
      pusherBeamService.subscribe(props.token, pusher.beam.interest);
    } else {
      pusherBeamService.unSubscribe();
    }
  }, [props.isLoggedIn]);

  useOnMount(() => {
    if (navigator.serviceWorker) {
      const receivedPushNotification = (data: MessageEvent<NotificationBareData>) => {
        dispatch(PushNotificationStore.actions.unread.create(data.data));
      };

      navigator.serviceWorker.addEventListener("message", receivedPushNotification);
    }
  });

  const adminOrDifferentFromOnboarding = useMemo(() => {
    return props.isAdmin || mode !== AppModes.onboarding;
  }, [props.isAdmin]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter>
        <DiContext.Provider value={{ apiService, dispatch, entityServices }}>
          <Layout>
            <>
              <ScrollToTop />
              <Switch>
                <Route path="/" exact={true}>
                  <Redirect to="/auth/login" />
                </Route>

                <Route path="/privacy">
                  <PrivacyPage />
                </Route>

                <Route path="/terms">
                  <TermsPage />
                </Route>

                {adminOrDifferentFromOnboarding && (
                  <Route
                    path="/api/share/:id"
                    render={(routerProps) => <Redirect to={`/app/talk/${routerProps.match.params.id}`} />}
                  />
                )}

                <PublicRoute path="/auth" isLoggedIn={props.isLoggedIn}>
                  <>
                    <Route path="/auth/register" component={RegisterPage} />
                    <Route path="/auth/login" component={PasswordlessLogin} />
                    <Route path="/auth/reset-password" component={ResetPasswordPage} />
                    <Route path="/auth/confirm-reset-password" component={ConfirmResetPasswordPage} />
                    <Route path="/auth/passwordless-login-verify" component={PasswordlessSession} />
                  </>
                </PublicRoute>

                <PrivateRoute path="/app" isLoggedin={props.isLoggedIn}>
                  <>
                    <Route
                      path="/app/company-profile/:id"
                      exact={true}
                      render={(routerProps) => <Redirect to={`/app/booth/${routerProps.match.params?.id}`} />}
                    />
                    <Route path="/app/company-profile/:id/edit" component={CompanyUpdate} />
                    <Route path="/app/schedule" component={SchedulePage} />
                    <Route path="/app/booth/:id" component={BoothView} />

                    {props.me?.replayAccess && (
                      <>
                        <Route path="/app/replays" component={ReplayPage} />
                        <Route path="/app/replay/:id" component={ViewReplayPage} />
                      </>
                    )}

                    {adminOrDifferentFromOnboarding && (
                      <>
                        <Route path="/app/stage" component={StagePage} />
                        <Route path="/app/talk/:id" component={TrackPage} />
                      </>
                    )}

                    {props.isAdmin && !isMobile && (
                      <>
                        <Route path="/app/admin" component={AdminPage} />
                        <Route path="/app/live" component={Live} />
                      </>
                    )}

                    <CurrentTalkUpdater />
                  </>
                </PrivateRoute>
              </Switch>
            </>
          </Layout>
        </DiContext.Provider>
      </BrowserRouter>
    </Suspense>
  );
});
