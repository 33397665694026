import React, { useState, useRef, useEffect } from "react";
import { Modal, Carousel, Button } from "antd";
import { Link } from "react-router-dom";
import arrowRight from "assets/images/Icons-Angle.svg";
import slider1 from "assets/images/awg/intro-slider-images/slider-1.png";
// import slider2 from "assets/images/awg/intro-slider-images/slider-2.png";
// import slider3 from "assets/images/awg/intro-slider-images/slider-3.png";
import slider4 from "assets/images/awg/intro-slider-images/slider-4.png";
import slider5 from "assets/images/awg/intro-slider-images/slider-5.png";
// import slider6 from "assets/images/awg/intro-slider-images/slider-6.png";
import slider7 from "assets/images/awg/intro-slider-images/slider-7.png";
import { app } from "config";

interface IntroModalProps {
  sliderIsOpen: boolean;
  handleClose: () => void;
}

const slides = [
  {
    image: slider1,
    Body: () => (
      <div
        className="slider-content"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <h3>Thank you for joining us.</h3>
        <p>
          Let's start with your quick-fire orientation guide that will only take
          30 seconds to get you {app.name} ready!
        </p>
      </div>
    ),
  },
  {
    image: slider4,
    Body: () => (
      <div className="slider-content">
        <h3>Customize Your Schedule</h3>
        <p>
          Take control of your {app.name} journey and choose what sessions will move the needle for you.
          With over 100 speakers, make sure you head over to the <span>Schedule</span> page to save your
          favorite tracks and speakers.
        </p>
      </div>
    ),
  },
  {
    image: slider5,
    Body: () => (
      <div className="slider-content">
        <h3>Network in the Marketplace</h3>
        <p>
          The <span>Marketplace</span> is the go-to networking hub for doing
          business at {app.name}. Explore company booths to forge connections and
          make deals with companies that are waiting to meet with you.
        </p>
      </div>
    ),
  },
  {
    image: slider7,
    Body: ({ onClose }: { onClose: any }) => (
      <div className="slider-content">
        <h3>Build Your Profile</h3>
        <p>
          Your profile is your passport to {app.name}. Take 2 minutes to fill out
          your essential details to power-up our <span>Smart Match</span>{" "}
          recommendations for the show.
        </p>
        <Link className="build-profile-button" to="/app/me" onClick={onClose}>
          BUILD YOUR PROFILE NOW
        </Link>
      </div>
    ),
  },
];

const IntroModal = ({ sliderIsOpen, handleClose }: IntroModalProps) => {
  const carouselRef = useRef({
    goTo: () => {},
    next: () => {},
    prev: () => {},
    autoPlay: false,
    innerSlider: null,
  });

  const [slide, setSlide] = useState(0);
  const [renderCarusel, setRenderCarusel] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRenderCarusel(true);
    }, 450);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className="wrapper-intro-modal">
      {renderCarusel && (
        <Modal
          width={680}
          centered={true}
          bodyStyle={{ padding: 0 }}
          visible={sliderIsOpen}
          onOk={handleClose}
          onCancel={handleClose}
          destroyOnClose={true}
          footer={(
            <div
              className="footer-container"
              style={{
                justifyContent: (slide === 0 && "flex-end") || "space-between",
              }}
            >
              {slide !== 0 && (
                <Button
                  onClick={() => carouselRef?.current?.prev()}
                  className="intro-btn prev-button"
                >
                  <img src={arrowRight} alt="arrow left" /> PREVIOUS
                </Button>
              )}

              {slide !== slides.length - 1 && (
                <Button className="intro-btn" onClick={() => carouselRef?.current?.next()}>
                  NEXT <img src={arrowRight} alt="arrow right" />
                </Button>
              )}

              {slide === slides.length - 1 && (
                <Button className="intro-btn" onClick={handleClose}>
                  Finish
                </Button>
              )}
            </div>
          )}
          className="intro-modal"
        >
          <Carousel
            className="carusel-container"
            ref={carouselRef}
            afterChange={setSlide}
          >
            {slides.map((Item, i) => {
              return (
                <div
                  style={{
                    color: "#fff",
                    textAlign: "center",
                    background: "#364d79",
                  }}
                  key={i}
                >
                  <div className="container-intro-modal">
                    <img src={Item.image} width={"100%"} alt={`Slide ${i}`} />
                    <Item.Body onClose={handleClose} />
                  </div>
                </div>
              );
            })}
          </Carousel>
        </Modal>
      )}
    </div>
  );
};
export default IntroModal;
