import { UserEntity } from "app/infra/user";
import { UserAvatar } from "components/User/UserAvatar";
import { preventCopy } from "helpers";
import React from "react";
import { Link } from "react-router-dom";

interface SpeakerInformationProps {
  fullname: string;
  position: string;
  bio: string;
  user: UserEntity;
}

export const SpeakerInformation = ({
  fullname,
  position,
  bio,
  user,
}: SpeakerInformationProps) => (
  <div className="speaker-information-container">
    <div>
      <UserAvatar user={user} size={110} />
    </div>

    <div>
      <h1>{fullname}</h1>
      <span className="speaker-position">{position}</span>
      <p onCopy={preventCopy}>
        {bio}
      </p>
    </div>
  </div>
);
