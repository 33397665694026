import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { app } from "config";

import bannerHeaderSVG from "assets/images/awg/hero-top-banner.svg";

interface HeroMap {
  [key: string]: string;
}

export const Hero = () => {
  const location = useLocation();

  const titles: HeroMap = {
    "/app/lobby": `welcome to ${app.name}!`,
    "/app/stage": "replays",
    "/app/replays": "replays",
    "/app/schedule": "schedule",
    "/app/directory": "networking",
    "/app/perks": "perks",
    "/app/booths": "marketplace",
    "/app/user-profile": "directory",
  };

  const title = useMemo(() => {
    return titles[location.pathname.replace(/\/+$|[?|#].*$/, "")];
  }, [location.pathname]);

  if (!title) return null;

  return (
    <div className="page-hero-banner-wrapper">
      <div className="page-hero-banner" style={{ backgroundImage: `url(${bannerHeaderSVG})` }}>
        <h1>{title}</h1>

        {location.pathname === "/app/schedule" && (
          <div className="schedule-banner-info">
            <div className="schedule-banner-info-first">
              <h5>
                <span role="presentation" aria-hidden="true" style={{ marginRight: "8px" }}>
                  🎉
                </span>
                3-MONTHS ALL ACCESS REPLAYS!
              </h5>
            </div>

            <div className="schedule-banner-info-second" style={{ textAlign: "center" }}>
              <h5>Tap <b>“Watch Talk”</b> on a speech</h5>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
